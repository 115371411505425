.container {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  height: 0;
  overflow: hidden;
}

.logoContainer {
  height: 100%;
  margin-left: 19px;
  display: flex;
  flex-direction: row;
}

.logo {
  width: 32px;
  height: 100%;
}

.logoTitle {
  font-weight: 700;
  font-size: 18px;
  margin: 0px 12px;
}

.tabs {
  height: 100%;
  margin: 0px 3px;
  padding: 0px 5px;
}

.tabs > span {
  font-size: 15px;
}
