.img {
  height: 50px !important;
  width: 50px;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .title {
    max-width: calc(100vw - 420px);
  }
}
