.title {
  margin-top: 20px;
  margin-bottom: 10px;
}
.description {
  margin-top: 10px;
  margin-bottom: 20px;
}
.button {
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 350px;
  width: 80vw;
  min-height: 32px;
}
.img {
  width: 60vw;
  height: 60vw;
  max-width: 250px;
  max-height: 250px;
  margin-top: 30px;
}

.link {
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
