.container {
  min-height: 100vh;
}

.firstChar {
  font-size: 100px;
  font-weight: bold;
  font-family: "Establishment";
}

.secondChar {
  font-size: 100px;
  font-weight: bold;
  position: relative;
  right: 7px;
  text-shadow: -3px 0px 1px #ffffff, 0px 4px 1px #ffffff;
  font-family: "Establishment";
}

.thirdChar {
  font-size: 100px;
  font-weight: bold;
  position: relative;
  right: 14px;
  text-shadow: -3px 0px 1px #ffffff, 0px 4px 1px #ffffff;
  font-family: "Establishment";
}

.title {
  padding: 1px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.subTitle {
  padding: 1px;
  text-align: center;
  font-size: 14px;
  margin-top: 15px;
  text-transform: uppercase;
}

@media (max-width: 300px) {
  .firstChar {
    font-size: 70px;
    font-weight: bold;
    font-family: "Establishment";
  }

  .secondChar {
    font-size: 70px;
    font-weight: bold;
    position: relative;
    right: 4px;
    text-shadow: -3px 0px 1px #ffffff, 0px 4px 1px #ffffff;
    font-family: "Establishment";
  }

  .thirdChar {
    font-size: 70px;
    font-weight: bold;
    position: relative;
    right: 8px;
    text-shadow: -3px 0px 1px #ffffff, 0px 4px 1px #ffffff;
    font-family: "Establishment";
  }
}
