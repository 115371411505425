.container {
  overflow-x: auto;
}
.table {
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
}
.th {
  text-align: left;
  padding: 10px;
}
.td {
  text-align: left;
  padding: 10px;
}
.rotate {
  transform: rotate(180deg);
}
.rows {
  border-bottom: 1px solid #ddd;
}
.rows:hover {
  background-color: rgba(221, 221, 221, 0.3);
}
.order {
  width: 16px;
  height: 16px;
}
