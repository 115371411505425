.tirggerButton {
  opacity: 0.3;
  position: fixed !important;
  top: calc(100vh - 60px);
  right: 30px;
  z-index: 1001;
  border-radius: 30px !important;
}

.tirggerButton:hover {
  opacity: 1;
}

@media (max-width: 400px) {
  .tirggerButton {
    top: calc(100vh - 35px);
    right: 5px;
  }
}
