.guideImg {
  width: 100%;
  height: 190px;
}

.guideContainer {
  width: 320px;
  height: 435px;
}

.guideTitleContainer {
  padding: 16px 24px;
}

.guideDescription {
  text-align: left;
  padding: 0 24px;
}

.guideButtonContainer {
  padding: 24px;
}

.guideButtonContainer button {
  height: 50px;
}
