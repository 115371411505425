.dialog {
  width: 90% !important;
  height: 90vh !important;
  overflow-x: scroll !important;
  display: block !important;
  padding: 0px !important;
}

.dialog > div:nth-child(1) {
  margin: 0px;
  height: 100%;
  overflow-y: hidden;
}

.dialogHeader {
  width: 100%;
  height: 60px;
  padding: 10px;
}

.dialogSubHeader {
  height: 45px;
}
.dialogImg {
  height: 40px;
  width: 40px;
  border-radius: 5px;
}

.dialogTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100vw - 200px);
}

.frame {
  width: 100%;
  height: calc(100% - 60px);
  border-width: 0px;
  padding: 0px;
}
