.button {
  max-width: 350px;
  width: 80vw;
  margin-bottom: 20px;
}
.img {
  width: 60vw;
  height: 60vw;
  max-width: 250px;
  max-height: 250px;
  margin-top: 20px;
}
.container {
  min-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: white;
}
